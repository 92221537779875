import React, { useEffect, useState, useCallback } from 'react';
import Papa from 'papaparse';
import PlayerTile from './PlayerTile';
import { sortAndGroupPlayers, processPlayerData } from '../../../utils/sports/brcl/playerUtils';
import csvFilePath from '../../../data/sports/brcl/brclPlayersProfile.csv';
import './PlayerList.css';

const PlayerList = ({ onSelectionChange, players }) => {
  const [localPlayers, setLocalPlayers] = useState(players || []);

  // Fetch players from CSV if not provided through props
  useEffect(() => {
    if (players && players.length > 0) {
      setLocalPlayers(players);
    } else {
      const fetchPlayers = async () => {
        try {
          const response = await fetch(csvFilePath);
          const csvData = await response.text();
          
          Papa.parse(csvData, {
            header: true,
            complete: (result) => {
              const processedPlayers = processPlayerData(result.data);
              setLocalPlayers(processedPlayers);
            },
            error: (error) => {
              console.error('Error parsing CSV:', error);
            }
          });
        } catch (error) {
          console.error('Error fetching CSV:', error);
        }
      };
  
      fetchPlayers();
    }
  }, [players]);

  // Sort and group players for display
  const sortedPlayers = sortAndGroupPlayers(localPlayers);

  // Handle individual player selection
  const handlePlayerSelect = useCallback((selectedPlayer) => {
    const updatedPlayers = localPlayers.map(player =>
      player.PlayerName === selectedPlayer.PlayerName
        ? { ...player, isSelected: !player.isSelected }
        : player
    );
    setLocalPlayers(updatedPlayers);
    onSelectionChange(updatedPlayers.filter(player => player.isSelected), updatedPlayers);
  }, [localPlayers, onSelectionChange]);

  // Update selected players whenever the local players change
  useEffect(() => {
    const selectedPlayers = localPlayers.filter(player => player.isSelected);
    onSelectionChange(selectedPlayers, localPlayers);
  }, [localPlayers, onSelectionChange]);

  return (
    <div className="player-list-container">
      {sortedPlayers.map((player) => (
        <PlayerTile
          key={player.PlayerName}
          player={player}
          onSelect={handlePlayerSelect}
        />
      ))}
    </div>
  );
};

export default PlayerList;
