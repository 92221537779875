import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PlayerList from './PlayerList';
import { Button, Input, InputNumber } from 'antd';
import { generateBalancedTeams, calculateTeamRating, assignRandomCaptain } from '../../../utils/sports/brcl/teamUtils';
import { sortPlayersAlphabetically, addNewPlayer } from '../../../utils/sports/brcl/playerUtils';
import './BrclPage.css';

const BrclPage = () => {
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [allPlayers, setAllPlayers] = useState([]);
  const [newPlayerName, setNewPlayerName] = useState('');
  const [numberOfTeams, setNumberOfTeams] = useState(2);
  const navigate = useNavigate();

  const handleSelectionChange = useCallback((selected, all) => {
    setSelectedPlayers(selected);
    setAllPlayers(all);
  }, []);

  const handleAddPlayer = () => {
    const updatedPlayers = addNewPlayer(newPlayerName, allPlayers);
    setAllPlayers(updatedPlayers);
    setNewPlayerName('');
  };

  const handleSelectAll = useCallback(() => {
    const updatedPlayers = allPlayers.map(player => ({ ...player, isSelected: true }));
    setAllPlayers(updatedPlayers);
    setSelectedPlayers(updatedPlayers);
  }, [allPlayers]);

  const handleDeselectAll = useCallback(() => {
    const updatedPlayers = allPlayers.map(player => ({ ...player, isSelected: false }));
    setAllPlayers(updatedPlayers);
    setSelectedPlayers([]);
  }, [allPlayers]);

  const handleMakeTeams = useCallback(() => {
    const teams = generateBalancedTeams(selectedPlayers, numberOfTeams).map((team, index) => {
      const sortedPlayers = sortPlayersAlphabetically(team);
      return {
        id: `team-${index + 1}`,
        name: `Team ${index + 1}`,
        players: sortedPlayers,
      };
    });

    const teamsWithCaptains = assignRandomCaptain(teams);

    teamsWithCaptains.forEach((team, index) => {
      const ratings = calculateTeamRating(team.players);
      console.log(`Team ${index + 1} Ratings:`, ratings);
    });

    navigate('/brclmaketeams', {
      state: { teams: teamsWithCaptains, allPlayers },
    });
  }, [selectedPlayers, numberOfTeams, navigate, allPlayers]);

  return (
    <div className="brcl-page">
      <h1>BRCL Player Selection</h1>
      <div className="selection-info">
        Selected Players: {selectedPlayers.length}
      </div>
      <div className="add-player">
        <Input
          value={newPlayerName}
          onChange={(e) => setNewPlayerName(e.target.value)}
          placeholder="Enter new player name"
          style={{ marginRight: 8, width: 200 }}
        />
        <Button onClick={handleAddPlayer} disabled={!newPlayerName.trim()}>
          Add Player
        </Button>
      </div>
      <div className="selection-controls">
        <Button onClick={handleSelectAll} disabled={allPlayers.every(player => player.isSelected)}>
          Select All
        </Button>
        <Button onClick={handleDeselectAll} disabled={selectedPlayers.length === 0}>
          Deselect All
        </Button>
      </div>
      <PlayerList onSelectionChange={handleSelectionChange} players={allPlayers} />
      <div className="team-controls">
        <InputNumber
          min={2}
          max={4}
          value={numberOfTeams}
          onChange={setNumberOfTeams}
          addonBefore="Number of Teams"
        />
        <Button 
          type="primary" 
          onClick={handleMakeTeams}
          disabled={selectedPlayers.length < numberOfTeams}
        >
          Make Teams
        </Button>
      </div>
    </div>
  );
};

export default BrclPage;
